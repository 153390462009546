import React, { useEffect, useState } from "react";
import { lazy } from "react";
import { withTranslation } from "react-i18next";
import i18next from '../globals/translations'

const EDRMS = lazy(() => import("./IntegrationTypes/edrms"));
const CRM = lazy(() => import("./IntegrationTypes/crm"));
const DataSource = lazy(() => import("./IntegrationTypes/DataSource"));
const DigitalPost = lazy(() => import("./IntegrationTypes/DigitalPost"));
const DAM = lazy(() => import("./IntegrationTypes/dam"));

function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
function  getSlug(lang, slugs) {
  // Get the specific slug for each language
  const enSlug = slugs.find((slug) => {
    return slug.locale === "en";
  })?.slug;
  const dkSlug = slugs.find((slug) => {
    return slug.locale === "da";
  })?.slug;
  const seSlug = slugs.find((slug) => {
    return slug.locale === "se";
  })?.slug;
  const noSlug = slugs.find((slug) => {
    return slug.locale === "no";
  })?.slug;

  // Check which language we are looking for and construct the URL based on that
  if (window.dataType === "Article") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/blog/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/blog/" + dkSlug
      else
        return window.location.origin + "/dk/blog/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/blogg/" + seSlug
      else
        return window.location.origin + "/se/blogg/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/blogg/" + noSlug
      else
        return window.location.origin + "/no/blogg/" + enSlug
    }
  } else if (window.dataType === "Integration") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/dynamictemplate/integrations/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/dynamictemplate/integrationer/" + dkSlug
      else
        return window.location.origin + "/dk/dynamictemplate/integrationer/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/dynamictemplate/integrationer/" + seSlug
      else
        return window.location.origin + "/se/dynamictemplate/integrationer/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/dynamictemplate/integrasjoner/" + noSlug
      else
        return window.location.origin + "/no/dynamictemplate/integrasjoner/" + enSlug
    }
  } else if (window.dataType === "Open-position") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/open-positions/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/åbne-stillinger/" + dkSlug
      else
        return window.location.origin + "/dk/åbne-stillinger/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/lediga-tjänster/" + seSlug
      else
        return window.location.origin + "/se/lediga-tjänster/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/ledige-stillinger/" + noSlug
      else
        return window.location.origin + "/no/ledige-stillinger/" + enSlug
    }
  }
  return ""
}

function getUrlWithNewLanguageRedirect(lang, t) {
  if (!window.slugs) {
    // Get the list of routes in the current language
    const routes = i18next.getResourceBundle(i18next.language, 'routes');
    const currentPathname = decodeURI(window.location.pathname.replace(/\/+$/, ''));

    // Find the key for the url
    const currentRouteKey = routes ? Object.keys(routes)?.find((key) => routes[key] === currentPathname) : "";
    const newRouteKey = t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() });

    // If the key is found, return the corresponding url. If the key is an empty string, it must be the home page.
    if (newRouteKey !== "undefined")
      if (newRouteKey === "")
        return window.location.origin
      else
        return t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() })
    else
      return window.location.href
  } else if (window.slugs) {
    // If window.slugs exists, we must be on an article, so get the articles of other languages
    return getSlug(lang, window.slugs)
  }
}
function getUserLanguage(t) {
  // Browser language for redirecting to the correct language
  let location = navigator.language || navigator.userLanguage,
    cookie = getCookie("preferred_language"),
    redirectUrl = "",
    originUrl = window.location.origin,
    href = window.location.href
    
    if (href.charAt(href.length - 1) === "/")
      href = href.slice(0, -1)
  
    href = decodeURI(href)
  // Only do redirects if the user doesn't have query parameters in the url
  if (href.indexOf("?") < 0){
    // Check if cookie exists with preferred country, and redirect if it doesn't
    if (cookie === "" || cookie === null) {

      if (location !== null && location !== "") {
        switch (location) {
          case 'da':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'da-DK':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'da-GL':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo-DK':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo-FO':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'sv':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'se':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'se-SE':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nb':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'no':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)

            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nn':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nb-NO':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nn-NO':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          default:
            redirectUrl = getUrlWithNewLanguageRedirect("EN", t)
            redirectUrl = decodeURI(redirectUrl)

            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
        }
      }
    }else{
      redirectUrl = getUrlWithNewLanguageRedirect(cookie, t)

      if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
        window.location.href = redirectUrl
    }
  }
}
const Integration = props => {
  const { t } = props;
  const [integration, setIntegration] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          // const { t } = this.props;
          setIsFetching(true);
          const variables = {
            slug: props.match.params.slug
          };
          const url = t('integration_api_url') + `slug=${variables.slug}`;
          const result = await fetch(url).then((res) => res.json());
          if (result.integration.length > 0) {
            setIntegration(result.integration[0]);
            window.slugs = result.integration[0].localizations
            window.dataType = "Integration"
            getUserLanguage(t);
          }
          else
            setIntegration({});

          setIsFetching(false);
        } catch (error) {
          console.error(JSON.stringify(error, undefined, 2));
          setIsFetching(false);
        }
      };

      fetchData();
    },
    [props.match.params.slug]
  );
  return (
    <section>
      {isFetching ? (
        <div style={{ display: "block", width: "100%", height: "2000px" }}></div>
      ) : (
        integration?.system?.toLowerCase() === "edrms" ? integration && (
          <EDRMS integration={integration} t={t} />
        ) :
          integration?.system?.toLowerCase() === "crm" ?
            integration && (<CRM integration={integration} t={t} />) :
            integration?.system?.toLowerCase() === "digital_post" ?
              integration && (<DigitalPost integration={integration} t={t} />) :
              integration?.system?.toLowerCase() === "data_source" ?
              integration && (<DataSource integration={integration} t={t} />) :
              integration?.system?.toLowerCase() === "dam" ?
                integration && (<DAM integration={integration} t={t} />) : ""

      )}
    </section>
  )
}

export default withTranslation(i18next.language)(Integration);
