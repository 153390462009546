import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next.on('languageChanged', (lng) => {
    if (lng === "dk")
        document.documentElement.setAttribute('lang', 'da');
    else if (lng === "se")
        document.documentElement.setAttribute('lang', 'sv');
    else
        document.documentElement.setAttribute('lang', lng);
})

i18next
    .use(HttpApi) 
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'dk', 'se', 'no'],   
        fallbackLng: 'en',
        debug: false,
        // Options for language detector
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            htmlTag: document.documentElement
        },
        backend: {
            loadPath: '/lang/{{lng}}/translations.json',
        },
    })

// TODO: Add all routes here as well
i18next.addResourceBundle('en', 'routes', {
    "header_link_root": "",
    "header_link_dynamictemplate": "/dynamictemplate",
    "integrations_link": "/dynamictemplate/integrations",
    "header_link_assist": "/assist-pro",
    "assist_pro_business_case" : "/assist-pro/business-case",
    "header_link_connect365": "/connect-365",
    "header_link_signatureCloud": "/signaturecloud",
    "header_link_blog": "/blog",
    "header_link_case_studies": "/case-studies",
    "header_link_events": "/events",
    "header_link_direct": "/direct",
    "header_link_about": "/about",
    "header_link_contact": "/contact",
    "header_link_career": "/career",
    "header_link_customers": "/customers",
    "header_link_partners": "/partners",
    "header_link_support": "/support",
    "header_link_pricing": "/pricing",
    "header_link_bookdemo": "/book-demo",
    "link_bookdemo_sc": "/signaturecloud/book-demo",
    "header_link_privacy_policy": "/privacy-policy",
    "header_link_tos" : "/terms-of-service",
    "thank_you": "/thank-you",
    "privacy_policy_link": "/privacy-policy",
    "cookie_policy_link": "/cookiepolicy",
    "design_templates_link": "/dynamictemplate/design-templates",
    "rule_engine_link": "/dynamictemplate/rule-engine",
    "e-learning_link": "/dynamictemplate/e-learning",
    "insight_link": "/dynamictemplate/insight",
    "consult_link": "/consult"
}, true, true)
    .addResourceBundle('dk', 'routes', {
        "header_link_root": "/dk",
        "header_link_dynamictemplate": "/dk/dynamictemplate",
        "integrations_link": "/dk/dynamictemplate/integrationer",
        "header_link_assist": "/dk/assist-pro",
        "assist_pro_business_case" : "/dk/assist-pro/business-case",
        "header_link_connect365": "/dk/connect-365",
        "header_link_signatureCloud": "/dk/signaturecloud",
        "header_link_direct": "/dk/direct",
        "header_link_blog": "/dk/blog",
        "header_link_case_studies": "/dk/cases",
        "header_link_events": "/dk/events",
        "header_link_about": "/dk/om-os",
        "header_link_career": "/dk/karriere",
        "header_link_customers": "/dk/kunder",
        "header_link_partners": "/dk/partnere",
        "header_link_contact": "/dk/kontakt",
        "header_link_support": "/dk/support",
        "header_link_pricing": "/dk/pris",
        "header_link_bookdemo": "/dk/book-demo",
        "link_bookdemo_sc": "/dk/signaturecloud/book-demo",
        "header_link_privacy_policy": "/dk/persondatapolitik",
        "header_link_tos" : "/dk/terms-of-service",
        "thank_you": "/dk/tak",
        "customers_link": "/dk/kunder",
        "privacy_policy_link": "/dk/persondatapolitik",
        "cookie_policy_link": "/dk/cookie-politik",
        "design_templates_link": "/dk/dynamictemplate/design-skabeloner",
        "rule_engine_link": "/dk/dynamictemplate/regelmotor",
        "e-learning_link": "/dk/dynamictemplate/e-laering",
        "insight_link": "/dk/dynamictemplate/insight",
    }, true, true)
    .addResourceBundle('no', 'routes', {
        "header_link_root": "/no",
        "header_link_dynamictemplate": "/no/dynamictemplate",
        "integrations_link": "/no/dynamictemplate/integrasjoner",
        "header_link_assist": "/no/assist-pro",
        "assist_pro_business_case" : "/no/assist-pro/business-case",
        "header_link_connect365": "/no/connect-365",
        "header_link_signatureCloud": "/no/signaturecloud",
        "header_link_blog": "/no/blogg",
        "header_link_case_studies": "/no/caser",
        "header_link_events": "/no/events",
        "header_link_direct": "/no/direct",
        "header_link_about": "/no/om-oss",
        "header_link_contact": "/no/kontakt",
        "header_link_career": "/no/karriere",
        "header_link_customers": "/no/kunder",
        "header_link_partners": "/no/partner",
        "header_link_support": "/no/support",
        "header_link_pricing": "/no/pris",
        "header_link_bookdemo": "/no/book-demo",
        "link_bookdemo_sc": "/no/signaturecloud/book-demo",
        "header_link_privacy_policy": "/no/personvernpolicy",
        "header_link_tos" : "/no/terms-of-service",
        "thank_you": "/no/takk",
        "customers_link": "/no/customers",
        "privacy_policy_link": "/no/personvernpolicy",
        "cookie_policy_link": "/no/cookiepolicy",
        "design_templates_link": "/no/dynamictemplate/design-maler",
        "rule_engine_link": "/no/dynamictemplate/regelmotor",
        "e-learning_link": "/no/dynamictemplate/e-laering",
        "insight_link": "/no/dynamictemplate/insight",
    }, true, true)
    .addResourceBundle('se', 'routes', {
        "header_link_root": "/se",
        "header_link_dynamictemplate": "/se/dynamictemplate",
        "integrations_link": "/se/dynamictemplate/integrationer",
        "header_link_assist": "/se/assist-pro",
        "assist_pro_business_case" : "/se/assist-pro/business-case",
        "header_link_connect365": "/se/connect-365",
        "header_link_signatureCloud": "/se/signaturecloud",
        "header_link_blog": "/se/blogg",
        "header_link_case_studies": "/se/cases",
        "header_link_events": "/se/events",
        "header_link_direct": "/se/direct",
        "header_link_about": "/se/om-oss",
        "header_link_contact": "/se/kontakt",
        "header_link_career": "/se/karriär",
        "header_link_customers": "/se/kunder",
        "header_link_partners": "/se/partner",
        "header_link_support": "/se/support",
        "header_link_pricing": "/se/pris",
        "header_link_bookdemo": "/se/book-demo",
        "link_bookdemo_sc": "/se/signaturecloud/book-demo",
        "header_link_privacy_policy": "/se/personuppgiftspolicy",
        "header_link_tos" : "/se/terms-of-service",
        "thank_you": "/se/tack",
        "privacy_policy_link": "/se/personuppgiftspolicy",
        "cookie_policy_link": "/se/cookiepolicy",
        "design_templates_link": "/se/dynamictemplate/design-mallar",
        "rule_engine_link": "/se/dynamictemplate/regelmotor",
        "e-learning_link": "/se/dynamictemplate/e-utbildning",
        "insight_link": "/se/dynamictemplate/insight",
    }, true, true)
export default i18next;
