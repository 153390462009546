import { React } from './react'
import { Suspense } from "react";
import ReactDOM from "react-dom";
import "./tailwind.output.css";
import App from "./components/App";


ReactDOM.render(
    <Suspense fallback={<div></div>}>
        <App />
    </Suspense>
    ,
    document.getElementById("root"));

