import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import i18next from '../globals/translations'


function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
function  getSlug(lang, slugs) {
  // Get the specific slug for each language
  const enSlug = slugs.find((slug) => {
    return slug.locale === "en";
  })?.slug;
  const dkSlug = slugs.find((slug) => {
    return slug.locale === "da";
  })?.slug;
  const seSlug = slugs.find((slug) => {
    return slug.locale === "se";
  })?.slug;
  const noSlug = slugs.find((slug) => {
    return slug.locale === "no";
  })?.slug;

  // Check which language we are looking for and construct the URL based on that
  if (window.dataType === "Article") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/blog/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/blog/" + dkSlug
      else
        return window.location.origin + "/dk/blog/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/blogg/" + seSlug
      else
        return window.location.origin + "/se/blogg/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/blogg/" + noSlug
      else
        return window.location.origin + "/no/blogg/" + enSlug
    }
  } else if (window.dataType === "Integration") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/dynamictemplate/integrations/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/dynamictemplate/integrationer/" + dkSlug
      else
        return window.location.origin + "/dk/dynamictemplate/integrationer/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/dynamictemplate/integrationer/" + seSlug
      else
        return window.location.origin + "/se/dynamictemplate/integrationer/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/dynamictemplate/integrasjoner/" + noSlug
      else
        return window.location.origin + "/no/dynamictemplate/integrasjoner/" + enSlug
    }
  } else if (window.dataType === "Open-position") {
    if (lang.toLowerCase() === "en") {
      return window.location.origin + "/open-positions/" + enSlug
    }
    else if (lang.toLowerCase() === "dk") {
      if (dkSlug)
        return window.location.origin + "/dk/åbne-stillinger/" + dkSlug
      else
        return window.location.origin + "/dk/åbne-stillinger/" + enSlug
    }
    else if (lang.toLowerCase() === "se") {
      if (seSlug)
        return window.location.origin + "/se/lediga-tjänster/" + seSlug
      else
        return window.location.origin + "/se/lediga-tjänster/" + enSlug
    }
    else if (lang.toLowerCase() === "no") {
      if (noSlug)
        return window.location.origin + "/no/ledige-stillinger/" + noSlug
      else
        return window.location.origin + "/no/ledige-stillinger/" + enSlug
    }
  }
  return ""
}

function getUrlWithNewLanguageRedirect(lang, t) {
  if (!window.slugs) {
    // Get the list of routes in the current language
    const routes = i18next.getResourceBundle(i18next.language, 'routes');
    const currentPathname = decodeURI(window.location.pathname.replace(/\/+$/, ''));

    // Find the key for the url
    const currentRouteKey = routes ? Object.keys(routes)?.find((key) => routes[key] === currentPathname) : "";
    const newRouteKey = t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() });

    // If the key is found, return the corresponding url. If the key is an empty string, it must be the home page.
    if (newRouteKey !== "undefined")
      if (newRouteKey === "")
        return window.location.origin
      else
        return t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() })
    else
      return window.location.href
  } else if (window.slugs) {
    // If window.slugs exists, we must be on an article, so get the articles of other languages
    return getSlug(lang, window.slugs)
  }
}
function getUserLanguage(t) {
  // Browser language for redirecting to the correct language
  let location = navigator.language || navigator.userLanguage,
    cookie = getCookie("preferred_language"),
    redirectUrl = "",
    originUrl = window.location.origin,
    href = window.location.href
    
    if (href.charAt(href.length - 1) === "/")
      href = href.slice(0, -1)
  
    href = decodeURI(href)
  // Only do redirects if the user doesn't have query parameters in the url
  if (href.indexOf("?") < 0){
    // Check if cookie exists with preferred country, and redirect if it doesn't
    if (cookie === "" || cookie === null) {

      if (location !== null && location !== "") {
        switch (location) {
          case 'da':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'da-DK':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'da-GL':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo-DK':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'fo-FO':
            redirectUrl = getUrlWithNewLanguageRedirect("DK", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
              window.location.href = redirectUrl
            }
            break;
          case 'sv':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'se':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'se-SE':
            redirectUrl = getUrlWithNewLanguageRedirect("SE", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nb':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'no':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)

            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nn':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nb-NO':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          case 'nn-NO':
            redirectUrl = getUrlWithNewLanguageRedirect("NO", t)
            redirectUrl = decodeURI(redirectUrl)
            
            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
            break;
          default:
            redirectUrl = getUrlWithNewLanguageRedirect("EN", t)
            redirectUrl = decodeURI(redirectUrl)

            if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
              window.location.href = redirectUrl
        }
      }
    }else{
      redirectUrl = getUrlWithNewLanguageRedirect(cookie, t)

      if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
        window.location.href = redirectUrl
    }
  }
}

const OpenPosition = props => {
  const { t } = props;
  const [openPosition, setOpenPosition] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const variables = {
            slug: props.match.params.slug
          };
          const url = t('open_position_api_url') + `&slug=${variables.slug}`;
          const result = await fetch(url).then((res) => res.json());
          if (result.position.length > 0) {
            setOpenPosition(result.position[0]);
            window.slugs = result.position[0].localizations
            window.dataType = "Open-position"
            getUserLanguage(t);
          }
          if(result.position.length < 1){
            window.location.replace(window.location.origin);
          }
          setIsFetching(false);
        } catch (error) {
          console.error(JSON.stringify(error, undefined, 2));
          setIsFetching(false);
        }
      };

      fetchData();
    },
    [props.match.params.slug]
  );
    return (
      <section>
        {isFetching ? (
        ""
      ) : (
        openPosition && (
          (
            <Helmet>
              <title>{openPosition?.title + t('dania_software')}</title>
              <meta name="title" content={openPosition?.title + t('dania_software')} />
            </Helmet>
          ))
      )}
      {isFetching ? (
        <div style={{display: "block", width: "100%", height: "2000px"}}></div>
      ) : (
        openPosition && (
          <article>   
            <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                <svg
                  className="absolute top-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>
                <svg
                  className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg
                  className="absolute bottom-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg max-w-prose mx-auto">
                <h1>
                  <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    {openPosition?.title}                    
                  </span>
                </h1>
                <div key={openPosition?.id} className="mt-6 prose prose-dania prose-lg text-gray-500 mx-auto"> 
                  <Markdown
                    source={openPosition?.content?.markdown}
                    className="Article-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      ))};
    </section>
    )
  };
  export default withTranslation(i18next.language)(OpenPosition);
  